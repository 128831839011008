import { useDispatch } from 'react-redux';
import {
  TextField,
  makeStyles,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid} from "@material-ui/data-grid";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {MenuItem} from "@mui/material";
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {createCustomer, getCustomers} from "../../../actions/storeActions";
import {useAlert} from "../../../utilities/hooks";
import EditCustomer from "../LocalDeliveryEditCustomer/EditCustomer";
import {getBranchList} from "../../../actions/commonActions";

const useStyle = makeStyles(() => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex'
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  header: {
    display: "flex"
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  dialogButton: {
    width: '260px',
    height: '56px'
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 630,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const INIT_CUSTOMER = {
  id: '',
  alias: '',
  partner_id: '',
  partner_name: '',
  // password: '',
  receipt_name: '',
  address: '',
  postal_code: '',
  time_zone: '',
  pickup_warehouse: '',
  phone: '',
  email: '',
  need_pickup: '',
  is_active: ''
};

const ALERT = {
  SEARCH_SUCCESS  : "FETCH DATA SUCCESS",
  SEARCH_FAILED   : "FETCH DATA FAILED",
  MODIFY_SUCCESS  : "MODIFY CUSTOMER SUCCESS",
  MODIFY_FAILED   : "MODIFY CUSTOMER FAILED",
  CREATE_SUCCESS  : "CREATE CUSTOMER SUCCESS",
  CREATE_FAILED   : "CREATE CUSTOMER FAILED",
};

const ERR_MSG = "Please fill in the required blanks";

function LocalDeliveryCustomer(props) {
  const {customerOpen} = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const alertDialog = useAlert();

  const [loading, setLoading] = useState(false);

  const [branchList, setBranchList] = useState([]);
  const [customer, setCustomer] = useState(INIT_CUSTOMER);
  const [customerID, setCustomerID] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [partnerID, setPartnerID] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [customerErr, setCustomerErr] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [createCustomerOpen, setCreateCustomerOpen] = useState(false);
  const [updateCustomerOpen, setUpdateCustomerOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'client_id',
      headerName: 'Client ID',
      flex: 1,
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      flex: 1,
    },
    {
      field: 'partner_status',
      headerName: 'Partner Status',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'customer_id',
      headerName: 'Customer ID',
      flex: 1,
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      flex: 1,
    },
    {
      field: 'customer_status',
      headerName: 'Customer Status',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'email',
      headerName: 'Contact Email',
      flex: 1,
    },
    {
      field: 'need_pickup',
      headerName: 'Need Pickup',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'No') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
  ];

  const handleBranchChange = (e) => {
    const whs_id = e.target.value;
    const time_zone = (branchList.find(branch => branch.id === whs_id)).time_zone;
    setCustomer(prev => ({...prev, pickup_warehouse: whs_id, time_zone: time_zone}));
  };

  const handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      handleSearch(customerID, customerName, partnerID, partnerName);
    }
  }

  const handleSetPartnerId = (e) => {
    let id = e.target.value;
    if (!isNaN(id)) {
      setPartnerID(id);
    }
  }

  const handleSetCustomerId = (e) => {
    let id = e.target.value;
    if (!isNaN(id)) {
      setCustomerID(id);
    }
  }

  const handleRowClick = async (selectedRow) => {
    setLoading(true);
    try {
      let res = (await dispatch(getCustomers(selectedRow.row['customer_id'])))[0];

      let customerInfo = {...INIT_CUSTOMER};
      for (let key in customerInfo) {
        customerInfo[key] = res[key];
      }

      setCustomer(customerInfo);
      setUpdateCustomerOpen(true);
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    } finally {
      setLoading(false);
    }
  }

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row) => ({
      id: row.id,
      client_id: row.partner_id,
      partner_name: row.partner_name,
      partner_status: row.is_active ? 'Active' : 'Pending',
      customer_id: row.id,
      customer_name: row.alias,
      customer_status: row.is_active ? 'Active' : 'Pending',
      email: row.email,
      need_pickup: row.need_pickup ? 'Yes' : 'No',
    }));

    setRows(updatedRows);
  };

  const handleCreateCustomer = async (event) => {
    event.preventDefault();

    if (customer['alias'].length === 0) {
      dispatch(showAlertSnackbar({ title: ERR_MSG }));
      setCustomerErr(true);
      setErrorMessage(ERR_MSG);
      return;
    }
    try {
      let params = {
        alias: customer['alias'],
        receipt_name: customer['receipt_name'],
        address: customer['address'],
        phone: customer['phone'],
        email: customer['email'],
        // password: customer['password'],
        partner_id: customer['partner_id'],
        pickup_warehouse: customer['pickup_warehouse'],
        time_zone: customer['time_zone']
      }
      await dispatch(createCustomer(params));
      dispatch(showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' }));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    }
    setCustomerErr(false);
    setErrorMessage("");
  }

  const handleSearch = useCallback(async (customerID, customerName, partnerID, partnerName) => {
    setLoading(true);
    try {
      const customers = await dispatch(getCustomers(customerID, customerName, partnerID, partnerName));

      handleUpdateRow(customers);
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
      setCustomerID('');
      setCustomerName('');
      setPartnerID('');
      setPartnerName('');
    }
  }, [dispatch])

  const getWarehousePairs = useCallback(async () => {
    try {
      let res = await dispatch(getBranchList());

      let branches = res.map(x => ({id: x.id, name: x.name, time_zone: x.time_zone}));
      branches.unshift({id: '', name: '', time_zone: ''});
      setBranchList(branches);
    } catch (e) {
      console.log('Fetch branch list failed', e);
    }
  }, [dispatch])

  function handleEditClose() {
    setUpdateCustomerOpen(false);
  }

  useEffect(() => {
    if(customerOpen) {
      handleSearch('', '', '', '');
      getWarehousePairs();
    }
  }, [customerOpen, handleSearch, getWarehousePairs])

  useEffect(() => {
    if(createCustomerOpen) {
      setCustomerErr(false);
      setErrorMessage('');
    }
  }, [createCustomerOpen])

  useEffect(() => {
    if(!createCustomerOpen && !updateCustomerOpen) {
      setCustomer(INIT_CUSTOMER);
    }
  }, [createCustomerOpen, updateCustomerOpen])

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Customer</p>
        </div>
        <Button
          id={'create-new-customer'}
          className={classes.button}
          style={{ marginRight: 30, }}
          startIcon={<AddIcon />}
          onClick={() => setCreateCustomerOpen(true)}
        >
          New Customer
        </Button>

        <Dialog maxWidth='lg' open={createCustomerOpen} onClose={() => setCreateCustomerOpen(false)}>
          <DialogTitle style={{fontWeight: 600}}>New Customer</DialogTitle>
          <DialogContent>
            <div style={{ display: 'table-cell' }}>
              <TextField
                required
                className={classes.dialogButton}
                id={'dialog_customer_name'}
                label="Customer Name"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={customerErr}
                helperText={errorMessage}
                onChange={(e) => setCustomer(prev => ({...prev, alias: e.target.value}))}
              />
              <TextField
                className={classes.dialogButton}
                style={{marginLeft: '36px'}}
                id={'dialog_partner_id'}
                label="Partner Id"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) => setCustomer(prev => ({...prev, partner_id: e.target.value}))}
              />
              {/*<TextField*/}
              {/*  className={classes.dialogButton}*/}
              {/*  style={{marginLeft: '36px'}}*/}
              {/*  id={'dialog_password'}*/}
              {/*  label="Password"*/}
              {/*  InputLabelProps={{ shrink: true }}*/}
              {/*  variant="outlined"*/}
              {/*  onChange={(e) => setCustomer(prev => ({...prev, password: e.target.value}))}*/}
              {/*/>*/}
            </div>
            <div style={{marginTop: '36px'}}>
              <TextField
                className={classes.dialogButton}
                id={'name_on_invoice'}
                label="Name On Invoice"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) => setCustomer(prev => ({...prev, receipt_name: e.target.value}))}
              />
              <TextField
                style={{width: '556px', marginLeft: '36px'}}
                id={'dialog_street_address'}
                label="Street Address"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) => setCustomer(prev => ({...prev, address: e.target.value}))}
              />
            </div>
            <div style={{marginTop: '36px'}}>
              <TextField
                className={classes.dialogButton}
                id={'dialog_drop_off_whs'}
                select
                label="Drop-Off/Pick-Up WHS"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={handleBranchChange}
              >
                {branchList.map((whs) => (
                  <MenuItem key={whs.id} value={whs.id}>
                    {whs.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.dialogButton}
                style={{marginLeft: '36px'}}
                id={'dialog_time_zone'}
                disabled
                label="Time Zone"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={customer['time_zone']}
              />
            </div>
            <div style={{marginTop: '36px'}}>
              <TextField
                className={classes.dialogButton}
                id={'dialog_contact_number'}
                label="Contact Number"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) => setCustomer(prev => ({...prev, phone: e.target.value}))}
              />
              <TextField
                style={{width: '556px', marginLeft: '36px'}}
                id={'dialog_contact_email'}
                label="Contact Email"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onChange={(e) => setCustomer(prev => ({...prev, email: e.target.value}))}
              />
            </div>

          </DialogContent>
          <DialogActions style={{ marginBottom: 24 }}>
            <Button
              id={'cancel-create-customer'}
              autoFocus
              className={classes.buttonText}
              style={{ marginRight: 24 }}
              onClick={() => setCreateCustomerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              id={'create-customer'}
              variant='contained'
              onClick={handleCreateCustomer}
              className={classes.buttonSystem}
              style={{ marginRight: 24 }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>


      <div className={classes.searchBar}>
        <TextField
          id={'customer_id_text'}
          label={'Customer ID'}
          variant='outlined'
          onChange={handleSetCustomerId}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={customerID}
          style={{width: '14vw', marginLeft: 16}}
        />

        <TextField
          id={'customer_name_search'}
          label={'Customer Name'}
          variant='outlined'
          onChange={e => setCustomerName(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={customerName}
          style={{width: '14vw', marginLeft: 36}}
        />

        <TextField
          id={'partner_id_text'}
          label={'Partner ID'}
          variant='outlined'
          onChange={handleSetPartnerId}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={partnerID}
          style={{width: '14vw', marginLeft: 36}}
        />

        <TextField
          id={'partner_name_search'}
          label={'Partner Name'}
          variant='outlined'
          onChange={e => setPartnerName(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={partnerName}
          style={{width: '14vw', marginLeft: 36}}
        />

        <IconButton
          id={'customer_search_button'}
          style={{marginLeft: 36}}
          size={'large'}
          color={'primary'}
          onClick={() => handleSearch(customerID, customerName, partnerID, partnerName)}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              color: "#577699",
              fontSize: 18,
              fontWeight: 600,
              marginLeft: 18,
              marginTop: 15
            }}
          >
            Customer List
          </div>
        </div>
        <DataGrid
          className={classes.table}
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={(selectedRow) => handleRowClick(selectedRow)}
        />
      </div>
      <EditCustomer
        openDialog={updateCustomerOpen}
        closeDialog={handleEditClose}
        customerInfo={customer}
        branches={branchList}
      />
    </div>
  );
}

export default LocalDeliveryCustomer;