import { useDispatch } from 'react-redux';
import {
  TextField,
  makeStyles,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core';
import React, {useState} from 'react';
import {DataGrid} from "@material-ui/data-grid";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
// import {useAlert} from "../../../utilities/hooks";
import {
  downloadBlockedAddress,
  getBlockedAddress,
  uploadBlockedAddress,
  deleteMultipleRecords
} from "../../../actions/addressManagementActions";
import DragDropFileUpload from "../../DragDropFileUpload";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DocumentDetailDisplay from "../../DocumentDetailDisplay";
import {exportHelper} from "../../../utilities/common";

const useStyle = makeStyles((theme) => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex'
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  header: {
    display: "flex"
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#1976d2",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#1976d2",
    },
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonText: {
    color: "#1976d2",
    width: 100,
    // height: 19,
    // margin: '2px 0 3px',
    // fontSize: "14px",
    fontWeight: 600,
    // fontStretch: 'normal',
    // fontStyle: 'normal',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: 'rgba(2, 136, 209, 0.04)'

    },
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  framedButton: {
    color: " #2a3848",
    borderRadius: 4,
    border: "solid 1px #2a3848",
    fontSize: "12px",
    maxHeight: "36px",
    fontWeight: 600,
    marginTop: 15,
    '&:hover': {
      background: "rgba(25, 118, 210, 0.10)",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  tableHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    color: "#577699",
    fontSize: 18,
    fontWeight: 600,
    marginLeft: 18,
    marginTop: 15
  },
  table: {
    height: 630,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const ALERT = {
  SEARCH_SUCCESS    : "FETCH DATA SUCCESS",
  SEARCH_FAILED     : "FETCH DATA FAILED",
  UPLOAD_SUCCESS    : "UPLOAD SUCCESS",
  UPLOAD_FAILED     : "UPLOAD FAILED",
  DOWNLOAD_SUCCESS  : "DOWNLOAD SUCCESS",
  DOWNLOAD_FAILED   : "DOWNLOAD FAILED",
  DELETE_SUCCESS    : "DELETE SUCCESS ",
  DELETE_FAILED     : "DELETE FAILED",
};

// const BLOCKED_ADDRESS = {
//   101: 'usps',
//   102: 'unreachable',
//   103: 'mountain',
//   104: 'military',
//   105: '3rd Party Post Office',
// };
//
// const INIT = {
//   address: '',
//   zipcode: '',
//   type: '',
//   notes: '',
// };

function BlockedAddress(props) {

  const dispatch = useDispatch();
  const classes = useStyle();
  // const alertDialog = useAlert();
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [type, setType] = useState(0);
  const [file, setFile] = useState('');
  const [uploadFailedMsg, setUploadFailedMsg] = useState('');

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadFailedDialogOpen, setUploadFailedDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // useEffect(() => {
  //   console.log('AAA', selectedRows);
  // }, [selectedRows]);

  const typeList = [
    {
      name: "All",
      type: 0
    },
    {
      name: "US Post Office",
      type: 101
    },
    {
      name: "Unreachable",
      type: 102
    },
    {
      name: "Mountain Area",
      type: 103
    },
    {
      name: "Military Area",
      type: 104
    },
    {
      name: "3rd Party Post Office",
      type: 105
    }
  ]

  const columns = [
    {
      field: 'address',
      headerName: 'Address',
      flex: 1
    },
    {
      field: 'zipcode',
      headerName: 'Zip Code',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1
    },
  ];

  const handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      handleSearch();
    }
  }

  // const handleRowClick = async (selectedRow) => {
  //   setLoading(true);
  //   try {
  //     let res = await dispatch(getPartners(selectedRow.row['partner_id']));
  //     setPartner(res[0]);
  //     setUpdatePartnerOpen(true);
  //   } catch (e) {
  //     alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
  //     alertDialog.showAlert();
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row) => ({
      id: row.id,
      address: row.address,
      zipcode: row.zipcode,
      type: row.type,
      notes: row.notes,
    }));

    setRows(updatedRows);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {

      const typeToUse = type === 0 ? '' : type;
      let res = await dispatch(getBlockedAddress(address, zipcode, typeToUse));

      handleUpdateRow(res);
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      dispatch(showAlertSnackbar({message: 'Please select a file.', type: 'error'}));
      return;
    }

    setLoading(true);
    try {
      let formData = new FormData();
      formData.append('file', file);
      await dispatch(uploadBlockedAddress(formData));
      dispatch(showAlertSnackbar({message: ALERT.UPLOAD_SUCCESS, type: 'success'}));
    } catch (e) {
      const errorMessage = e.message?.errors || 'An unexpected error occurred';
      setUploadFailedMsg(errorMessage);
      setUploadFailedDialogOpen(true);
      dispatch(showAlertSnackbar({message: ALERT.UPLOAD_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {

    if (selectedRows.length === 0) {
      dispatch(showAlertSnackbar({message: 'Please select rows to be downloaded.', type: 'error'}));
      return;
    }

    setLoading(true);
    try {
      const params = {
        id: JSON.stringify(selectedRows)
      };

      const res = await downloadBlockedAddress(params);
      exportHelper(res, 'blocked_addresses.xlsx');

      dispatch(showAlertSnackbar({message: ALERT.DOWNLOAD_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.DOWNLOAD_FAILED, type: 'error'}));
    } finally {
      setSelectedRows([]);
      setLoading(false);
    }
  };

  const deleteMultiple = async () => {

    if (selectedRows.length === 0) {
      dispatch(showAlertSnackbar({ message: 'Please select rows to be deleted.', type: 'error' }));
      return;
    }

    if (window.confirm('Are you sure to delete the selected address?')) {
      setLoading(true);
      try {
        const params = {
          id: JSON.stringify(selectedRows)
        };

        dispatch(deleteMultipleRecords(params));
        dispatch(showAlertSnackbar({ message: ALERT.DELETE_SUCCESS, type: 'success' }));
        dispatch(handleSearch);
      } catch (e) {
        dispatch(showAlertSnackbar({ message: ALERT.DELETE_FAILED, type: 'error' }));
      } finally {
        setSelectedRows([]);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className={classes.header}>

        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Blocked Address</p>
        </div>

        <Button
          className={classes.button}
          id={'new-partner'}
          style={{ marginRight: 30 }}
          startIcon={<AddIcon />}
          onClick={() => setUploadDialogOpen(true)}
        >
          New Addresses
        </Button>

        <Dialog maxWidth='sm' fullWidth open={uploadDialogOpen} onClose={() => {
          setUploadDialogOpen(false);
          setFile('');
        }}>
          <DialogTitle>Upload New Addresses</DialogTitle>
          <DialogContent>
            <DragDropFileUpload fileType={".xlsx"} onFileUpload={setFile} file={file}/>
            <hr style={{border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}/>
          </DialogContent>
          <DialogActions style={{ marginBottom: '30px', marginRight: '20px', marginTop: '10px' }}>
            <Button
              autoFocus
              className={classes.buttonText}
              onClick={() => {
                setUploadDialogOpen(false);
                setFile('');
              }}
            >
              BACK
            </Button>
            <Button
              variant='contained'
              onClick={handleUpload}
              style={{ backgroundColor: '#01579B', color: '#FFFFFF', fontWeight: '600' }}
            >
              UPLOAD
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth='sm' fullWidth open={uploadFailedDialogOpen} onClose={() => {
          setUploadFailedDialogOpen(false);
          setUploadFailedMsg('');
        }}>
          <DialogTitle>Upload Failed</DialogTitle>
          <DialogContent>
            <div style={{color: '#D32F2F', fontSize: 16, display: 'flex', flexDirection: "row", alignItems: "center",}}>
              <InfoOutlinedIcon style={{fontSize: 20, marginRight: 12, marginBottom: 3}}/>
              <div>Please correct the rows listed below and upload the adjustment file again</div>
            </div>
            <div style={{padding: 10}}><DocumentDetailDisplay file={file} state={'Complete'}/></div>

            <div>
              {uploadFailedMsg && uploadFailedMsg.length > 0 && (
                <ul style={{ color: '#D32F2F', fontSize: 14, padding: '10px', margin: '0' }}>
                  {uploadFailedMsg.map((msg, index) => (
                    <li key={index} style={{ marginBottom: '5px' }}>
                      {msg}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <hr style={{border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}/>
          </DialogContent>
          <DialogActions style={{marginBottom: '30px', marginRight: '20px', marginTop: '10px'}}>
            <Button
              autoFocus
              className={classes.buttonText}
              onClick={() => {
                setUploadFailedDialogOpen(false);
                setUploadFailedMsg('');
              }}
            >
              BACK
            </Button>
          </DialogActions>
        </Dialog>

      </div>

      <div className={classes.searchBar}>
        <TextField
          id={'address_text'}
          label={'Address'}
          variant='outlined'
          onChange={e => setAddress(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={address}
          style={{width: '14vw', marginLeft: 16}}
        />
        <TextField
          id={'zipcode_text'}
          label={'Zip Code'}
          variant='outlined'
          onChange={e => setZipcode(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={zipcode}
          style={{width: '14vw', marginLeft: 36}}
        />
        <FormControl variant="outlined" style={{width: '14vw', marginLeft: 36}}>
          <InputLabel id="select-label">Type</InputLabel>
          <Select
            id={'type_select'}
            label="Type"
            value={type}
            onChange={(e)=>{
              setType(e.target.value)
            }}
          >
            {typeList.map((type) => (
              <MenuItem value={type.type} key={type.type}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          id={'search_button'}
          style={{marginLeft: 36}}
          size={'large'}
          color={'primary'}
          onClick={handleSearch}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div className={classes.tableHeader}>
            Address Library
          </div>

          <Button className={classes.framedButton} style={{ marginRight: 36 }} onClick={handleDownload} >DOWNLOAD SELECTED</Button>
          <Button className={classes.framedButton} style={{ marginRight: 36 }} onClick={deleteMultiple} >DELETE SELECTED</Button>
        </div>

        <DataGrid
          className={classes.table}
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={(newRow) => {
            setSelectedRows(newRow);
          }}
          selectionModel={selectedRows}
        />
      </div>

    </div>
  );
}

export default BlockedAddress;