import { shangjiaServerInstance } from "../axios/instances";
import {
  GET_RECENT_BATCHLIST,
  GET_RECENT_BATCHLIST_SUCCESS,
  GET_RECENT_BATCHLIST_FAIL,
  GET_ORDERS_BY_BATCH_INTURN,
  GET_ORDERS_BY_BATCH_INTURN_SUCCESS,
  GET_ORDERS_BY_BATCH_INTURN_FAIL,
  GET_ORDER_PRINT_LABELS,
  GET_ORDER_PRINT_LABELS_SUCCESS,
  GET_ORDER_PRINT_LABELS_FAIL,
  GET_ORDERS_BY_CONDITON,
  GET_ORDERS_BY_CONDITON_SUCCESS,
  GET_ORDERS_BY_CONDITON_FAIL,
  GET_ORDERS_BY_CUSTOMER,
  GET_ORDERS_BY_CUSTOMER_SUCCESS,
  GET_ORDERS_BY_CUSTOMER_FAIL,
  CREATE_ACCOUNT as types
} from "./types"
import {execute} from "../network/executor";

export function getRecentBatchList(warehouse='', partnerId,days){
  return function(dispatch){
    dispatch({type: GET_RECENT_BATCHLIST})
    return shangjiaServerInstance.get(`/business/getrecentbatchlist?warehouse=${warehouse}&customer_id=${partnerId}&days=${days}`)
      .then(({data}) => {
        dispatch({
          type: GET_RECENT_BATCHLIST_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: GET_RECENT_BATCHLIST_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
export function getOrdersByCondition(customerId,warehouse,batchNo,trackingNo,dateFrom,dateTo,goodsType,rows,page){
  return function(dispatch){
    dispatch({type: GET_ORDERS_BY_CONDITON})
    return shangjiaServerInstance.get(`/business/getordersforshop?customer_id=${customerId}&warehouse=${warehouse}&batch_no=${batchNo}&tno=${trackingNo}&date_from=${dateFrom}&date_to=${dateTo}&goods_type=${goodsType}&rows=${rows}&page=${page}`)
      .then(({data}) => {
        dispatch({
          type: GET_ORDERS_BY_CONDITON_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: GET_ORDERS_BY_CONDITON_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getOrdersByBatchInturn(batchNo, warehouse){
  return function(dispatch){
    dispatch({type: GET_ORDERS_BY_BATCH_INTURN})
    return shangjiaServerInstance.get(`/business/getordersbybatchinturn?batch_no=${batchNo}&warehouse=${warehouse}`)
      .then(({data}) => {
        dispatch({
          type: GET_ORDERS_BY_BATCH_INTURN_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: GET_ORDERS_BY_BATCH_INTURN_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getOrderPrintLabels(warehouse, orderSns){
  return function(dispatch){
    dispatch({type: GET_ORDER_PRINT_LABELS})
    return shangjiaServerInstance.post(`/business/getorderprintlabels`, {
      warehouse: warehouse,
      order_sns: orderSns
    })
      .then(({data}) => {
        dispatch({
          type: GET_ORDER_PRINT_LABELS_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: GET_ORDER_PRINT_LABELS_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getOrdersByCustomer(customerId, from, to, tno, page, perpage){
  return function(dispatch){
    dispatch({type: GET_ORDERS_BY_CUSTOMER})
    return shangjiaServerInstance.get(`/orders?customer_id=${customerId}&from=${from}&to=${to}&tno=${tno}&page=${page}&perpage=${perpage}`)
      .then(({data}) => {
        dispatch({
          type: GET_ORDERS_BY_CUSTOMER_SUCCESS,
          payload: data.data
        })
        return Promise.resolve([data.data, data.paging]);
      }).catch(error => {
        dispatch({
          type: GET_ORDERS_BY_CUSTOMER_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export const createPartner = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`account/createpartner`, {...params}),
  {
    type: types.CREATE_PARTNER,
    success: types.CREATE_PARTNER_SUCCESS,
    fail: types.CREATE_PARTNER_FAIL,
  }
);

export const createCustomer = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`account/createcustomer`, {...params}),
  {
    type: types.CREATE_CUSTOMER,
    success: types.CREATE_CUSTOMER_SUCCESS,
    fail: types.CREATE_CUSTOMER_FAIL,
  }
);

export const getPartners = (id, name='') => (d) => execute(d)(
  shangjiaServerInstance.get(`account/partners?id=${id}&name=${name}`),
  {
    type: types.GET_PARTNER,
    success: types.GET_PARTNER_SUCCESS,
    fail: types.GET_PARTNER_FAIL
  }
);

export const getCustomers = (id='', alias='', partner_id='', partner_name='') => (d) => execute(d)(
  shangjiaServerInstance.get(`account/customers?id=${id}&alias=${alias}&partner_id=${partner_id}&partner_name=${partner_name}`),
  {
    type: types.GET_CUSTOMER,
    success: types.GET_CUSTOMER_SUCCESS,
    fail: types.GET_CUSTOMER_FAIL
  }
);

export const updateCustomer = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`account/updatecustomer`, {...params}),
  {
    type: types.UPDATE_CUSTOMER,
    success: types.UPDATE_CUSTOMER_SUCCESS,
    fail: types.UPDATE_CUSTOMER_FAIL,
  }
);

export const updatePartner = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`account/updatepartner`, {...params}),
  {
    type: types.UPDATE_PARTNER,
    success: types.UPDATE_PARTNER_SUCCESS,
    fail: types.UPDATE_PARTNER_FAIL,
  }
);