import React, {useState} from 'react';
import {makeStyles, CssBaseline} from '@material-ui/core';
import LocalDeliveryPartner from "../../components/LocalDelivery/LocalDeliveryPartner";
import LocalDeliveryCustomer from "../../components/LocalDelivery/LocalDeliveryCustomer";
import PrmFrame from "../../components/PrmFrame";
import Toolbar from "@material-ui/core/Toolbar";
import {PrmMain} from "../../components/PrmMain";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7'
  },
  content: {
    flexGrow: 1,
    width: '85vw',
    marginTop: 10
  }
}));

const ORGANIZATION_ACCESS_ID = 7;

function Organization() {
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState(0);
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PrmFrame
        ACCESS_ID={ORGANIZATION_ACCESS_ID}
        open={open}
        setOpen={setOpen}
        menuIndex={menuIndex}
        setMenuIndex={setMenuIndex}
      />
      <PrmMain
        open={open}
        className={classes.content}
      >
        <Toolbar />
        <div hidden={menuIndex!==0}>
          <LocalDeliveryPartner partnerOpen={menuIndex===0}/>
        </div>
        <div hidden={menuIndex!==1}>
          <LocalDeliveryCustomer customerOpen={menuIndex===1}/>
        </div>
      </PrmMain>
    </div>
  );
}

export default Organization;