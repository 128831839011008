import { useDispatch } from 'react-redux';
import {
  TextField,
  makeStyles,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Switch
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {updateCustomer} from "../../../actions/storeActions";
import {useAlert} from "../../../utilities/hooks";
import {MenuItem} from "@mui/material";

const useStyle = makeStyles(() => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex'
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  header: {
    display: "flex"
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#1976d2",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#1976d2",
    },
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  dialogButton: {
    width: '260px',
    height: '56px'
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 640,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const INIT_CUSTOMER = {
  id: '',
  alias: '',
  partner_id: '',
  partner_name: '',
  password: '',
  receipt_name: '',
  address: '',
  postal_code: '',
  time_zone: '',
  pickup_warehouse: '',
  phone: '',
  email: '',
  need_pickup: '',
  is_active: ''
};

const ALERT = {
  SEARCH_SUCCESS  : "Fetch Data Success",
  MODIFY_SUCCESS  : "MODIFY CUSTOMER SUCCESS",
  MODIFY_FAILED   : "MODIFY CUSTOMER FAILED"
};


function EditCustomer(props) {
  const {
    openDialog,
    customerInfo,
    closeDialog,
    branches
  } = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const alertDialog = useAlert();

  const [customer, setCustomer] = useState(INIT_CUSTOMER);
  const [updateCustomerOpen, setUpdateCustomerOpen] = useState(false);

  const handlePickupStatus = (e) => {
    let status = customer['need_pickup'] ? 0 : 1;
    setCustomer((e) => setCustomer(prev => ({...prev, need_pickup: status})));
  }

  const handleActiveStatus = (e) => {
    let status = customer['is_active'] ? 0 : 1;
    setCustomer((e) => setCustomer(prev => ({...prev, is_active: status})));
  }

  const handleBranchChange = (e) => {
    const whs_id = e.target.value;
    const time_zone = (branches.find(branch => branch.id === whs_id)).time_zone;
    setCustomer(prev => ({...prev, pickup_warehouse: whs_id, time_zone: time_zone}));
  };

  const handleUpdateCustomer = async (event) => {
    event.preventDefault();
    try {
      const params = Object.fromEntries(Object.entries(customer).map(([k, v]) => [k, v === null ? '' : v]));
      await dispatch(updateCustomer(params));
      dispatch(showAlertSnackbar({ title: ALERT.MODIFY_SUCCESS, type: 'success' }));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.MODIFY_FAILED, e.message);
      alertDialog.showAlert();
    }
  }

  function handleClose() {
    setUpdateCustomerOpen(false);
    closeDialog();
  }

  useEffect(() => {
    setUpdateCustomerOpen(openDialog);
    setCustomer(customerInfo);
  }, [openDialog, customerInfo])

  useEffect(() => {
    if(!updateCustomerOpen) {
      setCustomer(INIT_CUSTOMER);
    }
  }, [updateCustomerOpen])

  return (
    <Dialog maxWidth='lg' open={updateCustomerOpen} onClose={handleClose}>
      <DialogTitle style={{fontWeight: 600}}>Edit Customer</DialogTitle>
      <DialogContent>
        <div style={{ display: 'table-cell' }}>
          <TextField
            className={classes.dialogButton}
            id={'edit_dialog_customer_name'}
            label="Customer Name"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['alias']}
            onChange={(e) => setCustomer(prev => ({...prev, alias: e.target.value}))}
          />
          <TextField
            className={classes.dialogButton}
            style={{marginLeft: '36px'}}
            id={'edit_dialog_partner_id'}
            label="Partner Id"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['partner_id']}
            onChange={(e) => setCustomer(prev => ({...prev, partner_id: e.target.value}))}
          />
          <TextField
            disabled
            className={classes.dialogButton}
            style={{marginLeft: '36px'}}
            id={'edit_dialog_password'}
            label="Password"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['password']}
            onChange={(e) => setCustomer(prev => ({...prev, password: e.target.value}))}
          />
        </div>
        <div style={{marginTop: '36px'}}>
          <TextField
            className={classes.dialogButton}
            id={'edit_name_on_invoice'}
            label="Name On Invoice"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['receipt_name']}
            onChange={(e) => setCustomer(prev => ({...prev, receipt_name: e.target.value}))}
          />
          <TextField
            style={{width: '556px', marginLeft: '36px'}}
            id={'edit_dialog_street_address'}
            label="Street Address"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['address']}
            onChange={(e) => setCustomer(prev => ({...prev, address: e.target.value}))}
          />
        </div>
        <div style={{marginTop: '36px'}}>
          <TextField
            className={classes.dialogButton}
            id={'edit_dialog_drop_off_whs'}
            select
            label="Drop-Off/Pick-Up WHS"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['pickup_warehouse']}
            onChange={handleBranchChange}
          >
            {branches.map((whs) => (
              <MenuItem key={whs.id} value={whs.id}>
                {whs.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.dialogButton}
            style={{marginLeft: '36px'}}
            id={'edit_dialog_time_zone'}
            disabled
            label="Time Zone"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['time_zone']}
          />
          <TextField
            className={classes.dialogButton}
            style={{marginLeft: '36px'}}
            id={'edit_dialog_postal_code'}
            label="Postal Code"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['postal_code']}
            onChange={(e) => setCustomer(prev => ({...prev, postal_code: e.target.value}))}
          />
        </div>
        <div style={{marginTop: '36px'}}>
          <TextField
            className={classes.dialogButton}
            id={'edit_dialog_contact_number'}
            label="Contact Number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['phone']}
            onChange={(e) => setCustomer(prev => ({...prev, phone: e.target.value}))}
          />
          <TextField
            disabled
            style={{width: '556px', marginLeft: '36px'}}
            id={'edit_dialog_contact_email'}
            label="Contact Email"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={customer['email']}
          />
        </div>
        <div>
          <Grid container alignItems="center" spacing={1} style={{ marginTop: '30px' }}>
            <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)'}}>Need Pickup</Grid>
            <Grid item>
              <Switch
                id={'edit-need-pickup-switch'}
                className={classes.switch}
                checked={customer['need_pickup']}
                onChange={handlePickupStatus}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item>Pickup</Grid>
          </Grid>
        </div>
        <div>
          <Grid container alignItems="center" spacing={1} >
            <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)'}}>Customer Status</Grid>
            <Grid item>
              <Switch
                id={'edit-is-active-switch'}
                className={classes.switch}
                checked={customer['is_active']}
                onChange={handleActiveStatus}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item>Active</Grid>
          </Grid>
        </div>

      </DialogContent>
      <DialogActions style={{ marginBottom: 24 }}>
        <Button
          id={'cancel-edit-customer'}
          autoFocus
          className={classes.buttonText}
          style={{ marginRight: 24 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          id={'create-customer'}
          variant='contained'
          onClick={handleUpdateCustomer}
          className={classes.buttonSystem}
          style={{ marginRight: 24 }}
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditCustomer;