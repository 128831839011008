import { useDispatch } from 'react-redux';
import {
  TextField, makeStyles,
  Button, DialogTitle, DialogContent, DialogActions,
  Dialog, Switch, Grid
} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import {DataGrid} from "@material-ui/data-grid";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {showAlertSnackbar} from "../../../reducers/uiSlice";
import {createPartner, getPartners, updatePartner} from "../../../actions/storeActions";
import {useAlert} from "../../../utilities/hooks";

const useStyle = makeStyles((theme) => ({
  searchBar:{
    background: '#ffffff',
    borderRadius: '4px',
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    padding: "27px 39px 27px 16px",
    display: 'flex'
  },
  breadCrumbs: {
    marginLeft: 30,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  breadCrumbText: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: ' #000000',
    opacity: 0.8,
  },
  header: {
    display: "flex"
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#1976d2",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#1976d2",
    },
  },
  button: {
    backgroundColor: "#2a3848",
    color: " #fff",
    borderRadius: 4,
    padding: "15px 20px",
    fontSize: "12px",
    maxHeight: "36px",
    '&:hover': {
      background: "#3f556f",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)"
    }
  },
  buttonSystem: {
    backgroundColor: "#1976d2",
    color: " #fff",
    width: 108,
    height: 36,
    padding: "6px 16px",
    borderRadius: 4,
    boxShadow: " 0 3px 3px 0 rgba(0, 0, 0, 0.36)",
    fontSize: "12px",
    '&:hover': {
      background: "#1565c0",
    },
    '&:disabled': {
      background: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  tableArea: {
    background: "#ffffff",
    borderRadius: "4px",
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    margin: "25px 25px 24px",
    height: '90%'
  },
  table: {
    height: 630,
    margin: '10px 0 0',
    '& .MuiDataGrid-columnsContainer': {
      height: 56,
      backgroundColor: 'rgba(85, 166, 255, 0.1)',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,
      letterSpacing: 0.28,
      textAlign: 'left',
      color: 'rgba(185, 185, 185, 0.87)'
    },
    '& .MuiDataGrid-row': {
      height: 56,
      textAlign: 'left',
    },
    '& .green-text': {
      color: 'green'
    },
    '& .red-text': {
      color: 'red'
    }
  },
}));

const INIT_PARTNER = {
  id: '',
  name: '',
  client_id: '',
  client_secret: '',
  need_pickup: '',
  is_active: ''
};

const ALERT = {
  SEARCH_SUCCESS  : "FETCH DATA SUCCESS",
  SEARCH_FAILED   : "FETCH DATA FAILED",
  MODIFY_SUCCESS  : "MODIFY PARTNER SUCCESS",
  MODIFY_FAILED   : "MODIFY PARTNER FAILED",
  CREATE_SUCCESS  : "CREATE PARTNER SUCCESS",
  CREATE_FAILED   : "CREATE PARTNER FAILED",
};

const ERR_MSG = "Please fill in Partner Name";

function LocalDeliveryPartner(props) {
  const {partnerOpen} = props;

  const dispatch = useDispatch();
  const classes = useStyle();
  const alertDialog = useAlert();

  const [loading, setLoading] = useState(false);

  const [partner, setPartner] = useState(INIT_PARTNER);
  const [partnerID, setPartnerID] = useState('');
  const [partnerName, setPartnerName] = useState('');
  // const [partnerList, setPartnerList] = useState([]);
  const [partnerErr, setPartnerErr] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [createPartnerOpen, setCreatePartnerOpen] = useState(false);
  const [updatePartnerOpen, setUpdatePartnerOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: 'partner_id',
      headerName: 'Partner ID',
      flex: 1
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      flex: 1
    },
    {
      field: 'client_id',
      headerName: 'Client Id',
      flex: 1
    },
    {
      field: 'client_secret',
      headerName: 'Client Secret',
      flex: 1
    },
    {
      field: 'need_pickup',
      headerName: 'Need Pickup',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'No') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      cellClassName: (params) => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    }
  ];

  const handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      handleSearch(partnerID, partnerName);
    }
  }

  const handleSetPartnerId = (e) => {
    let id = e.target.value;
    if (!isNaN(id)) {
      setPartnerID(id);
    }
  }

  const handleRowClick = async (selectedRow) => {
    setLoading(true);
    try {
      let res = await dispatch(getPartners(selectedRow.row['partner_id']));
      setPartner(res[0]);
      setUpdatePartnerOpen(true);
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    } finally {
      setLoading(false);
    }
  }

  const handlePickupStatus = (e) => {
    let status = partner['need_pickup'] ? 0 : 1;
    setPartner((e) => setPartner(prev => ({...prev, need_pickup: status})));
  }

  const handleActiveStatus = (e) => {
    let status = partner['is_active'] ? 0 : 1;
    setPartner((e) => setPartner(prev => ({...prev, is_active: status})));
  }

  const handleCreatePartner = async (event) => {
    event.preventDefault();
    if (partner['name'].length === 0) {
      dispatch(showAlertSnackbar({ title: ERR_MSG }));
      setPartnerErr(true);
      setErrorMessage(ERR_MSG);
      return;
    }
    try {
      await dispatch(createPartner({name: partner['name']}));
      dispatch(showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' }));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    }
    setPartnerErr(false);
    setErrorMessage("");
  }

  const handleUpdatePartner = async (event) => {
    event.preventDefault();
    try {
      await dispatch(updatePartner({id: partner['id'], name: partner['name'], need_pickup: partner['need_pickup'], is_active: partner['is_active']}));
      dispatch(showAlertSnackbar({ title: ALERT.MODIFY_SUCCESS, type: 'success' }));
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.MODIFY_FAILED, e.message);
      alertDialog.showAlert();
    }
  }

  const handleSearch = useCallback(async (partnerID, partnerName) => {
    setLoading(true);
    try {
      let res = await dispatch(getPartners(partnerID, partnerName));
      // setPartnerList(res);

      const updatedRows = res.map((row) => ({
        id: row.id,
        partner_id: row.id,
        partner_name: row.name,
        client_id: row.client_id,
        client_secret: row.client_secret,
        need_pickup: row.need_pickup ? 'Yes' : 'No',
        status: row.is_active ? 'Active' : 'Pending',
      }));

      setRows(updatedRows);
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_SUCCESS, type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: ALERT.SEARCH_FAILED, type: 'error'}));
    } finally {
      setLoading(false);
      setPartnerID('');
      setPartnerName('');
    }
  }, [dispatch])

  useEffect(() => {
    if (partnerOpen) {
      handleSearch('', '');
    }
  }, [partnerOpen, handleSearch])

  useEffect(() => {
    if(createPartnerOpen) {
      setPartnerErr(false);
      setErrorMessage('');
    }
  }, [createPartnerOpen])

  useEffect(() => {
    if(!createPartnerOpen && !updatePartnerOpen) {
      setPartner(INIT_PARTNER);
    }
  }, [createPartnerOpen, updatePartnerOpen])

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.breadCrumbs}>
          <p style={{ color: "#577699", fontSize: "18px", fontWeight: "600", margin: "2px 0 1px" }}>Partner</p>
        {/*  <Breadcrumbs aria-label="breadcrumb">*/}
        {/*    <Link*/}
        {/*      underline="hover"*/}
        {/*      color="inherit"*/}
        {/*      to="/main"*/}
        {/*    >*/}
        {/*      Home*/}
        {/*    </Link>*/}
        {/*    <Typography className={classes.breadCrumbText} color="text.primary">Partner</Typography>*/}
        {/*  </Breadcrumbs>*/}
        </div>
        <Button
          className={classes.button}
          id={'new-partner'}
          style={{ marginRight: 30 }}
          startIcon={<AddIcon />}
          onClick={() => setCreatePartnerOpen(true)}
        >
          New Partner
        </Button>

        <Dialog maxWidth='md' open={createPartnerOpen} onClose={() => setCreatePartnerOpen(false)}>
          <DialogTitle style={{fontWeight: 600}}>New Partner</DialogTitle>
          <DialogContent>
            <div style={{ display: 'table-cell' }}>
              <TextField
                required
                id={'partner-name'}
                label="Name"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={partnerErr}
                helperText={errorMessage}
                onChange={(e) => setPartner(prev => ({...prev, name: e.target.value}))}
                style={{ minWidth:552 }}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ marginBottom: 24 }}>
            <Button
              id={'cancel-create-partner'}
              autoFocus
              className={classes.buttonText}
              style={{ marginRight: 24 }}
              onClick={() => setCreatePartnerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              id={'create-partner'}
              variant='contained'
              onClick={handleCreatePartner}
              className={classes.buttonSystem}
              style={{ marginRight: 24 }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={classes.searchBar}>
        <TextField
          id={'partner_id_text'}
          label={'Partner Id'}
          variant='outlined'
          onChange={handleSetPartnerId}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={partnerID}
          style={{width: '14vw', marginLeft: 16}}
        />
        <TextField
          id={'partner_name_text'}
          label={'Partner Name'}
          variant='outlined'
          onChange={e => setPartnerName(e.target.value)}
          onKeyDown={(e)=>handleKeyPress(e)}
          value={partnerName}
          style={{width: '14vw', marginLeft: 36}}
        />
        <IconButton
          id={'partner_search_button'}
          style={{marginLeft: 36}}
          size={'large'}
          color={'primary'}
          onClick={() => handleSearch(partnerID, partnerName)}
        >
          <SearchIcon fontSize={'inherit'}/>
        </IconButton>
      </div>

      <div className={classes.tableArea}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              color: "#577699",
              fontSize: 18,
              fontWeight: 600,
              marginLeft: 18,
              marginTop: 15
            }}
          >
            Partner List
          </div>
        </div>

        <DataGrid
          className={classes.table}
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={handleRowClick}
        />
      </div>

      <Dialog maxWidth='md' open={updatePartnerOpen} onClose={() => setUpdatePartnerOpen(false)}>
        <DialogTitle style={{fontWeight: 600}}>Edit Partner</DialogTitle>
        <DialogContent style={{ height: 400 }}>
          <div style={{ display: 'table-cell' }}>
            <TextField
              id={'update-partner-name'}
              label="Name"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={partner['name']}
              onChange={(e) => setPartner(prev => ({...prev, name: e.target.value}))}
              style={{ minWidth:552, marginTop: '30px' }}
            />
          </div>
          <div>
            <TextField
              disabled
              id={'update-client-id'}
              label="Client Id"
              InputLabelProps={{ shrink: true }}
              style={{ minWidth:552, marginTop: '36px' }}
              variant="outlined"
              value={partner['client_id']}
            />
          </div>
          <div>
            <TextField
              disabled
              id={'update-client-secret'}
              label="Client Secret"
              InputLabelProps={{ shrink: true }}
              style={{ minWidth:552, marginTop: '36px' }}
              variant="outlined"
              value={partner['client_secret']}
            />
          </div>
          <div>
            <Grid container alignItems="center" spacing={1} style={{ marginTop: '30px' }}>
              <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)'}}>Need Pickup</Grid>
              <Grid item>
                <Switch
                  id={'need-pickup-switch'}
                  className={classes.switch}
                  checked={partner['need_pickup']}
                  onChange={handlePickupStatus}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
              <Grid item>Pickup</Grid>
            </Grid>
          </div>
          <div>
            <Grid container alignItems="center" spacing={1} >
              <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)'}}>Partner Status</Grid>
              <Grid item>
                <Switch
                  id={'is-active-switch'}
                  className={classes.switch}
                  checked={partner['is_active']}
                  onChange={handleActiveStatus}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
              <Grid item>Active</Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions style={{ marginBottom: 24 }}>
          <Button
            id={'cancel-update-partner'}
            autoFocus
            className={classes.buttonText}
            style={{ marginRight: 24 }}
            onClick={() => setUpdatePartnerOpen(false)}
          >
            Cancel
          </Button>
          <Button
            id={'update-partner'}
            variant='contained'
            onClick={handleUpdatePartner}
            className={classes.buttonSystem}
            style={{ marginRight: 24 }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default LocalDeliveryPartner;